<template>
  <div class="current-plan-tab">
    <a-typography-title
      :level="5"
      style="display: contents"
    >
      {{ $t('components.subscriptionSettings.title') }}
    </a-typography-title>
    <a-divider />
    <SubscriptionSettingsInfoCard />
    <a-divider />
    <div style="display: flex; flex-direction: column; gap: 8px; margin-bottom: 24px;">
      <SubscriptionSettingsAddChangePaymentMethod />
      <EDUApplicationString />
      <EnterpriseContactUsString />
    </div>
    <SubscriptionSettingsManageBlock />
  </div>
</template>

<script>
import { computed, createVNode, defineComponent, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import SubscriptionSettingsInfoCard from '@/components/subscriptionSettings/SubscriptionSettingsInfoCard.vue'
import SubscriptionSettingsManageBlock from '@/components/subscriptionSettings/SubscriptionSettingsManageBlock.vue'
import EDUApplicationString from '@/components/EDUApplicationString.vue'
import EnterpriseContactUsString from '@/components/subscriptionSettings/EnterpriseContactUsString.vue'
import SubscriptionSettingsAddChangePaymentMethod
  from '@/components/subscriptionSettings/SubscriptionSettingsAddChangePaymentMethod.vue'

export default defineComponent({
  name: 'SubscriptionSettings',
  components: { SubscriptionSettingsAddChangePaymentMethod, EnterpriseContactUsString, EDUApplicationString, SubscriptionSettingsManageBlock, SubscriptionSettingsInfoCard },
  setup () {
    const migratedModalShown = !!localStorage.getItem('migrated-modal-shown') || false
    const store = useStore()
    const { t } = useI18n()
    const isPaid = computed(() => store.getters['workspace/isPaid'])
    const showGlobalSettings = computed(() => store.getters['showGlobalSettings'])
    const currentPeriodEnds = computed(() => store.getters['subscription/currentPeriodEndsFormatted']())
    const globalSettingsActiveTab = computed(() => store.getters['globalSettingsActiveTab'])
    const isSubscription = computed(() => globalSettingsActiveTab.value === 'subscription')
    const pendingStripeSubscriptionUpdate = computed(() => store.getters['workspace/pendingStripeSubscriptionUpdate'])
    const isMigratedUser = computed(()=> store.getters['auth/userIsMigrated'])

    const showMigratedModal = () => {
      localStorage.setItem('migrated-modal-shown', 'true')
      return Modal.confirm({
        title: t('components.subscriptionSettings.migratedModalTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.subscriptionSettings.migratedModalContent'),
        okText: t('components.subscriptionSettings.migratedModalOkButtonText'),
        cancelText: t('components.subscriptionSettings.migratedModalCancelButtonText'),
        width: '768px',
        onOk () {
          store.dispatch('openUpdateSubscription')
        }
      })
    }

    watchEffect(()=>{
      if (showGlobalSettings.value && isSubscription.value) {
        store.dispatch('subscription/setWorkspaceCustomer')
        store.dispatch('workspace/updateBillingInfo')
        store.dispatch('subscription/getPaymentMethod')
        if (isPaid.value) {
          store.dispatch('subscription/geWorkspaceInvoices')
        }
      }
    })

    watchEffect(()=>{
      if (!migratedModalShown && isMigratedUser.value && pendingStripeSubscriptionUpdate.value) {
        showMigratedModal()
      }
    })

    return {
      currentPeriodEnds
    }
  }
})
</script>

<style lang="less" scoped>
.current-plan-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
}


</style>
