<template>
  <a-config-provider
    v-if="!loading"
    :theme="themeConfig"
  >
    <router-view />
    <ClarityHandler />
  </a-config-provider>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { DEFAULT_ACCENT_COLOR } from '@/constants'
import { useI18n } from 'vue-i18n'
import markerSDK from '@marker.io/browser'
import { loadIntercom } from '@/intercom'
import 'vue-tel-input/vue-tel-input.css'
import { theme } from 'ant-design-vue'
import { mapTokenToVars } from '@/utils'
import chroma from 'chroma-js'
import ClarityHandler from '@/components/handlers/ClarityHandler.vue'

const getSiderBgColorFromAccentColor = (color) => {
  return chroma(color).darken(3).darken(0.3).hex()
}
const getSiderBgColorHoverFromAccentColor = (color) => {
  return chroma(color).luminance(0.9).hex()
}

const DEFAULT_HOSTS = ['localhost', process.env.VUE_APP_BASE_HOST]

export default defineComponent({
  components: { ClarityHandler },
  setup () {
    const store = useStore()
    const { locale } = useI18n()
    const { useToken } = theme;
    const { token } = useToken();
    const accentColor = computed(() => store.getters['auth/accentColor'])
    const themeConfig = computed(() => store.getters['themeConfig'])
    const language = computed(() => store.getters['auth/language'])
    const initialValue = [
      {
        children: [
          { text: 'This is editable plain text, just like a <textarea>!' },
        ],
      },
    ]

    const loading = ref(true)

    onBeforeMount(() => {
      // Load Intercom
      loadIntercom()
    })

    const loadMarkerSDK = () => {
      markerSDK.loadWidget({
        project: '63b690a1cfb80dbd0562f6c6',
      })
    }

    onMounted(async () => {
      loadMarkerSDK()

      const hostName = window.location.hostname
      const domainName = hostName.split('.')[0]
      if (DEFAULT_HOSTS.some((domainName) => hostName.startsWith(domainName))) {
        await store.dispatch('changeAccentColor', DEFAULT_ACCENT_COLOR)
        loading.value = false
        return
      }
      store.dispatch('auth/getWhitelabelSettings', domainName).then(async () => {
        await store.commit('auth/SET_WORKSPACE_DOMAIN', domainName)
        await store.dispatch('changeAccentColor', accentColor.value)
        loading.value = false
      }).catch(e => {
        window.location.href = process.env.VUE_APP_DEFAULT_URL || 'https://staging.next.kitcast.tv'
      })
    })

    watchEffect(() => {
      locale.value = language.value
    })

    watchEffect(() => {
      if (token.value) {
        const vars = mapTokenToVars(token.value)
        for (const key in vars) {
          document.documentElement.style.setProperty(`--ant-${key}`, vars[key])
        }
        document.documentElement.style.setProperty(`--ant-layout-header-background`, getSiderBgColorFromAccentColor(token.value.colorPrimary))
        document.documentElement.style.setProperty(`--ant-layout-header-background-hover`, getSiderBgColorHoverFromAccentColor(token.value.colorPrimary))
      }
    });

    return {
      token,
      loading,
      themeConfig,
      initialValue
    }
  }
})
</script>

<style lang="less">

@import './styles/variables';

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
}


html body {
  .intercom-lightweight-app-launcher {
    bottom: 60px;
  }

  #hubspot-messages-iframe-container {
    bottom: 35px !important;
  }

  .intercom-namespace {
    .intercom-messenger-frame {
      bottom: 124px;
      height: min(~'704px, 100% - 144px');
    }
    .intercom-dfosxs {
      bottom: 60px;
    }
  }
  #app {
    height: 100%;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .no-arrow {
    .ant-popover-arrow {
      display: none;
    }
  }
  .ant-picker-dropdown .ant-picker-footer-extra {
    padding: 0 12px;
  }
  .ant-dropdown.select-dropdown {
    z-index: 10002;
  }
  --alert-bg-color: #FF5D5D;
  .ant-layout-header, .ant-menu.ant-menu-dark {
    background-color: transparent;
  }

  .full-height {
    height: 100%;
  }
  .spacer {
    flex: 1;
  }
  .no-border {
    border: 0;
  }
  a, a.ant-typography, .ant-typography .ant-typography-expand, .ant-typography .ant-typography-edit, .ant-typography .ant-typography-copy {
    color: var(--ant-primary-color);
    &:hover {
      color: var(--ant-primary-color-hover);
    }
  }
  .ant-layout {
    background-color: transparent;
    .ant-layout-sider.dark, .ant-layout-header.dark {
      background-color: var(--ant-layout-header-background);
    }
  }
  .ant-tag-success {
    color: var(--ant-primary-color);
    background: var(--ant-primary-1);
    border-color: var(--ant-primary-color);
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon.ant-badge {
    line-height: 16px;
    display: inline;
  }
  .ant-tree {
    flex: 1;
    overflow-x: auto;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }
  .border-r {
    border-right: solid 1px #f0f0f0;
  }
  .ant-dropdown {
    z-index: 10000;
  }
  .ant-layout-sider-children {
    flex-direction: column;
    display: flex;
    .ant-menu.flex {
      flex: 1;
      flex-direction: column;
      display: flex;
    }
  }
  .ant-menu {
    font-weight: 500;
  }
  .ant-layout-sider.ant-layout-sider-dark, .ant-layout .ant-layout-header {
    //background-color: @sidebar-menu-background;
  }
  .ant-tree.groups-sidebar-tree {
    background: transparent;
    color: var(--ant-layout-header-background-hover);
    font-weight: 500;
    .ant-tree-switcher {
      background: transparent;
    }
    .ant-tree-indent-unit::before {
      border-color: @menu-item-color;
    }
    .ant-tree-node-content-wrapper {
      line-height: 28px;
      min-height: 28px;
      padding: 0;
      overflow: hidden;
      .ant-tree-iconEle {
        flex-shrink: 0;
      }
      .ant-tree-title {
        overflow: hidden;
        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .ant-tree-node-content-wrapper .ant-dropdown-trigger {
      color: var(--ant-layout-header-background);
    }
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: var(--ant-layout-header-background-hover);
      color: var(--ant-layout-header-background);
    }
    .ant-tree-node-content-wrapper:hover {
      background-color: var(--ant-layout-header-background-hover);
      color: var(--ant-layout-header-background);
    }
  }
  .sider-menu.ant-layout-sider {
    background: @groups-sidebar-background;
    display: flex;
    .ant-card.upgrade-card {
      width: calc(~'100% - 16px');
      margin-bottom: 16px;
    }
    .ant-menu.ant-menu-inline {
      border: 0;
    }
    .ant-layout-sider-children {
      flex: 1;
      border-right: solid 1px @border-color-light;
      max-height: 100%;
      overflow-x: hidden;
    }
  }
  .ant-modal-wrap {
    z-index: 10000;
  }
  .ant-modal-mask {
    z-index: 10000;
  }
  .ant-tooltip {
    z-index: 10001
  }
  .ant-select-dropdown {
    z-index: 10002;
  }
  .ant-image-preview-mask {
    z-index: 10003;
  }
  .ant-image-preview-wrap {
    z-index: 10004;
  }
  .ant-popconfirm {
    z-index: 10050;
  }
  .ant-message {
    z-index: 10051;
  }
  .ant-divider-horizontal.small {
    margin-top:8px;
    margin-bottom: 8px;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .multirow-checkbox-group .ant-checkbox-group-item {
    margin-bottom: 8px;
  }

  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
    justify-content: center;
    > button {
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .ant-tour .ant-tour-inner .ant-tour-footer {
    position: relative;
  }
  .ant-tour .ant-tour-inner .ant-tour-close {
    display: none;
  }
  .full-height-modal {
    .ant-modal-content {
      height: calc(~"100vh - 160px");
      display: flex;
      flex-direction: column;
      .ant-modal-body {
        flex: 1;
        overflow-x: auto;
      }
    }
  }
  .table-padded {
    padding: 16px;
  }

  //.ant-layout.dark, .ant-layout-footer.dark  {
  //  background-color: @menu-dark-submenu-bg;
  //}

  .fc {
    .fc-event {
      user-select: none;
    }
    --fc-bg-event-opacity: 1;
    .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
      box-shadow: none;
    }
    .fc-timegrid-slots {
      tbody > tr:first-child {
        .fc-timegrid-slot-label-cushion {
          display: none;
        }
      }
      .fc-timegrid-slot-label-cushion {
        padding: 0 4px;
        transform: translateY(-13px);
        font-size: 11px;
        background-color: #fff;
        margin-right: 4px;
        color: @text-color-secondary;
      }
    }
    .fc-day-today {
      .fc-col-header-cell-cushion {
        color: var(--ant-primary-color) !important;
      }
    }
    :not(.fc-dayGridMonth-view) ~ .fc-day:hover {
      position: relative;
      z-index: 10;
    }

    .fc-timegrid-col.fc-day-today, .fc-daygrid-day.fc-day-today {
      background-color: var(--ant-primary-1) !important;
    }
    .fc-timegrid-axis-cushion {
      font-size: 12px;
      color: @text-color-secondary;
    }
    :not(.fc-dayGridMonth-view) ~ .fc-day:hover {
      z-index: auto;
    }
    .fc-timegrid-col-bg {

    }
    .fc-timegrid-bg-harness:hover {
      z-index: 21 !important;
      filter: brightness(103%);
    }
    .fc-bg-event {
      padding: 2px 4px;
      border: solid 1px #fff;
      border-radius: 2px;
      i {
        color: #fff;
        font-size: 12px;
        font-style: normal;
      }
    }
    .fc-timeGridWeek-view .fc-event {
      &.fc-timegrid-event-short {
        .fc-event-main {
          &:hover {
            span[data-v-app] {
              > span {
                position: absolute;
                z-index: 100;
                overflow: visible;
                i, b {
                  overflow: visible;
                }
              }
            }
          }
          span[data-v-app] {
            > span {
              flex-wrap: nowrap;
              i {
                flex: 0 0 auto;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              b {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .fc-event-main {
        span[data-v-app] {
          > span {
            margin-left: 2px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              overflow: visible;
              i {
                overflow: visible;
              }
            }
            b {
              order: 2;
              font-weight: normal;
              color: @text-color-inverse;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            i {
              order: 1;
              color: @text-color-inverse;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1 0 100%;
              text-transform: capitalize;
              font-weight: bold;
              margin-right: 4px;
              font-style: normal;
            }
          }
        }
      }
    }
    .fc-dayGridMonth-view, .fc-more-popover{
      .fc-event {
        .fc-event-main {
          span[data-v-app] {
            > span {
              display: flex;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 2px;
              &:hover {
                overflow: visible;

                i {
                  overflow: visible;
                }
              }

              b {
                order: 2;
                font-weight: normal;
                color: @text-color-inverse;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              i {
                order: 1;
                color: @text-color-inverse;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
                font-weight: bold;
                margin-right: 4px;
                font-style: normal;
              }
            }
          }
        }
      }
    }
    .fc-button {
      text-transform: capitalize !important;
    }

  }
  .hidden {
    display: none;
  }
  .spacer {
    flex: 1;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-fast-enter-active,
  .fade-fast-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-fast-enter-from,
  .fade-fast-leave-to {
    opacity: 0;
  }
  .full-modal {
    &.subscription-modal {
      .ant-modal {
        max-width: 1048px;
        min-height: 300px;
        margin: auto;
        height: auto;
      }
    }
    .ant-modal {
      max-width: 100%;
      top: 0;
      margin: auto;
      height: 100%;
      padding: 60px;
      overflow: hidden;
    }
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ant-modal-body {
      flex: 1;
      overflow-x: auto;
    }
  }
  .media-modal {
    .ant-modal-content {
      overflow: hidden;
    }
    .ant-modal-body {
      padding: 0;
      overflow: hidden;
      .ant-layout {
        height: 100%;
      }
    }
  }
  .underline-link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .ant-badge.small {
    .ant-badge-count {
      border-radius: 8px;
      box-shadow: none;
      color: #fff;
      font-size: 11px;
      height: 16px;
      line-height: 16px;
      min-width: 16px;
      padding: 0 2px;
      span {
        color: #fff;
      }
    }
  }

  .icon.button {
    cursor: pointer;
  }

  .anticon.icon-button {
    cursor: pointer;
    color: #9fa2a5;
    &:hover {
      color: #2A3037;
    }
    &.icon-active {
      color: var(--ant-primary-color);
    }
  }


  .top-navbar {
    .ant-layout-header, .ant-menu, .actions, .ant-layout-sider-children {
      border-bottom: solid 1px @border-color-light;
    }
    .top-navbar-title {
      background-color: @root-sidebar-background;
      border-right: solid 1px @border-color-light;
      padding: 0;
      text-transform: capitalize;
      font-weight: 500;
      .ant-layout-sider-children {
        margin-left: 24px;
      }
    }
    .actions {
      border-bottom: solid 1px @border-color-light;
      padding-right: 20px;
      .anticon {
        font-size: 20px;
      }
      color: @text-color-secondary;
      &:hover {
        color: @text-color;
      }
    }
  }
  .m-phone-number-input {
    .m-input-wrapper.maz-rounded {
      border-radius: 0;
      border-color: #d9d9d9;
      border-width: 1px;
      &.maz-border-info {
        border-color: var(--ant-primary-color)
      }
      input.m-phone-number-input__input.m-input-input {
        &.--focused {
          font-size: 14px;
        }
        font-size: 14px;
      }
      label.m-input-label {
        font-size: 14px;
      }
    }
  }
  .vue-tel-input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: #fff;
    input {
      background-color: transparent;
    }
  }
  .vue-tel-input:focus-within {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px var(--ant-primary-color-outline);
  }
  .vue-tel-input.has-error {
    border-color: var(--ant-error-color-hover);
    box-shadow: 0 0 0 2px var(--ant-error-color-outline);
  }
  .vti__dropdown-list {
    width: 300px;
    height: 150px;
  }
  .vti__dropdown {
    padding: 6px;
  }
  .fc .fc-timegrid-axis-frame {
    justify-content: center;
  }
}

.movable-modal {
  pointer-events: none;
  .ant-modal .ant-modal-content {
    padding: 12px;
  }
}

body p {
  margin-bottom: 0;
}


</style>
