<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const billingDevicesQuantity = computed(() => store.getters['workspace/billingDevicesQuantity'])

defineProps({
  currentBillingAmount: Number
})

</script>

<template>
  <a-card>
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettingsInfoCardEDUStarter.planName') }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettingsInfoCardEDUStarter.includesNumberOfLicenses', {count: billingDevicesQuantity}) }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">

</style>
